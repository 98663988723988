import React from 'react';
import cn from 'classnames';
import st from './ModalContainer.module.scss';

export function ModalContainer({
  children, open, styles, modalContentStyles,
}) {
  return (
    <div className={cn(st.modalContainer, styles, { [st.open]: open })}>
      <div className={cn(st.modalContent, modalContentStyles)}>
        {children}
      </div>
    </div>
  );
}
