import ReactGA from 'react-ga4';

export function createEvent(click, category, label, value) {
  window.gtag('event', click, {
    event_category: category,
    event_label: label,
    value,
  });
}

export function gaPosClick() {
  ReactGA.event('pos_click', {
    pos_click: 'pos_click',
  });
}

export function gaRealizationInStore() {
  ReactGA.event('realization_in_store', {
    realization_in_store: 'realization_in_store',
  });
}

export function gaLoginSendCodeClick() {
  ReactGA.event('login_page_user_click_send_me_code', {
    login_page_user_click_send_me_code: 'login_page_user_click_send_me_code',
  });
}

export function gaChipperMeButtonClick() {
  ReactGA.event('chipper_me_button', {
    chipper_me_button: 'chipper_me_button',
  });
}

export function gaDownloadApplicationClick() {
  ReactGA.event('click_on_download_application', {
    click_on_download_application: 'click_on_download_application',
  });
}

export function gaUserSubmitContactUsRequest() {
  ReactGA.event('user_submit_contact_us_request', {
    user_submit_contact_us_request: 'user_submit_contact_us_request',
  });
}
