import { TRANSLATIONS } from '../../_constants';
import './CouponDistance.scss';

function CouponDistance({ distance, locale, online }) {
  return (
    <div className="coupon-distance">
      {!online && (
        <span>
          {distance.toFixed(1)} {locale === 'he' ? 'ק"מ' : 'km'}
        </span>
      )}
      {online && <span className="online">Online</span>}
    </div>
  );
}

export default CouponDistance;
