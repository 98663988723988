import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { generatePath, withRouter } from 'react-router-dom';
import cn from 'classnames';
import ch from '../assets/images/lang/ch.svg';
import en from '../assets/images/lang/en.svg';
import he from '../assets/images/lang/he.svg';
import ru from '../assets/images/lang/ru.svg';
import lang from '../assets/images/lang/icon.svg';
import st from './LangSwitch.module.scss';

import { LANG_NAMES } from '../_constants';

const langIcons = {
  ch,
  en,
  he,
  ru,
};

class LangSwitch extends Component {
  state = {
    cacheMaps: {},
  };

  setLanguage = (lang) => {
    const { pathname } = this.props.history.location;
    const url = this.props.history.location.pathname.split('/');

    url[1] = ':lang';

    window.location.href = generatePath(url.join('/'), { lang });
  };

  render() {
    return (
      <div className={cn('langs_block', { [st.rtl]: this.props.locale === 'he' })}>
        {LANG_NAMES.map((language, i) => (
          <div
            key={i}
            className={` ${this.props.locale === language.locale ? 'selected_lang lang_block' : 'lang_block'} ${language.locale === 'he' && 'dir-rtl'}`}
            onClick={() => this.setLanguage(language.locale)}
          >
            <img src={langIcons[language.locale]} />
            <p>{language.name}</p>
            {this.props.locale === language.locale && <img src={lang} />}
          </div>
        ))}
      </div>
    );
  }
}

export default withRouter(LangSwitch);

LangSwitch.propTypes = {
  locale: PropTypes.string.isRequired,
  setLanguage: PropTypes.func,
};
