import { applicationsConstants } from '../_constants/application.constant';
import { applicationService } from '../_services';

export function getApplicationStatus(status) {
  return (dispatch) => {
    applicationService.settingsApplications(status).then((data) => {
      dispatch({ type: applicationsConstants.SET_APPLICATIONS_STATUS, status: data });
    });
  };
}

export function getSeo() {
  return (dispatch) => {
    applicationService.getSEO()
      .then(
        (data) => {
          dispatch({ type: applicationsConstants.GET_SEO, data });
        },
      );
  };
}
