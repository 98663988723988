import React from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import cn from 'classnames';
import { loginUser, setupPreviousStep, loginUserWithToken } from '../../_actions';
import TranslationContainer from '../../_components/TranslationContainer';
import LoginMobileContainer from '../../_components/LoginMobileContainer';
import logoSvg from '../../assets/images/logo-footer.svg';
import xSvg from '../../assets/images/x.svg';
import loadingSvg from '../../assets/images/loading.svg';
import appleSvg from '../../assets/images/footer/cib_apple.svg';
import brandGoogleSvg from '../../assets/images/brands_google.svg';
import facebookSvg from '../../assets/images/fa-brands_facebook.svg';
import st from './LoginModal.module.scss';

class LoginModal extends React.Component {
  state = {
    isLogging: false,
    visible: null,
    isNavigationMobileIos: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.isLogging !== state.isLogging) {
      return {
        isLogging: props.isLogging,
      };
    }

    if (props.mobileStep && props.mobileStep !== state.mobileStep) {
      return {
        mobileStep: props.mobileStep,
      };
    }

    return null;
  }

  componentDidMount() {
    const { loginUserWithToken, language } = this.props;

    if (window.location.search && window.location.search.includes('token')) {
      loginUserWithToken(window.location.search.split('=')[1], this.onSuccess);
    }

    window.onMessage = (message) => {
      const user = JSON.parse(message);
      if (user.id) this.responseFacebook(user);
    };

    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      this.setState({ isNavigationMobileIos: true });
    }
  }

  onSuccess = (isAgree) => {
    const { history, language } = this.props;

    if (isAgree) {
      history.push(`/${language}/`);
    } else {
      history.push(`/${language}/profile`);
    }
  };

  closeLoginModal = () => {
    this.setState({ visible: false });
  };

  responseFacebook = (response) => {
    const { loginUser, language } = this.props;

    loginUser({ type: 'facebook', data: response }, this.onSuccess);
    dataLayer.push({ event: 'login-success' });
    FB.getLoginStatus((response) => {
      statusChangeCallback(response);
    });
  };

  webviewLogin = () => {
    window.ReactNativeWebView.postMessage('facebookLogin');
  };

  render() {
    const { modalLogin, language, status } = this.props;
    const showModal = this.props.showModal || this.closeLoginModal;
    const { isLogging, isNavigationMobileIos } = this.state;

    const hebrew = language === 'he';
    return (
      <Modal
        show={
          (this.state.visible ?? modalLogin) || (window.location.search && window.location.search.includes('token'))
        }
        onHide={() => showModal()}
        size="xl"
        className={hebrew ? 'rtl-class modal_login' : 'ltr-class modal_login'}
        style={{ direction: hebrew ? 'rtl' : 'ltr' }}
      >
        <Modal.Body className="login_block ModalLogin">
          <div className="mobile_logo_login">
            <div>
              <img alt="logo-footer" src={logoSvg} />
            </div>
          </div>

          <div className="close_button" onClick={() => showModal()}>
            <img alt="x" src={xSvg} />
          </div>

          <div className="logo_big ModalLogin__Presentation">
            <img alt="logo-footer" src={logoSvg} />
          </div>

          <div className={cn('login_form ModalLogin__Form', st.modalLogin)}>
            {isLogging && (
              <div className="loading_form">
                <img alt="loading" src={loadingSvg} />
              </div>
            )}

            <LoginMobileContainer
              onSuccess={this.onSuccess}
              callback={() => {
                !this.props.showModal && showModal();
              }}
            />

            {this.props.mobileStep !== 3 && (
              <>
                <div className="text-uppercase text-center mt-4 or_block">
                  <p>
                    <TranslationContainer translationKey="or_login" />
                  </p>
                </div>
                <div className={cn('ModalLogin__Form-SocialLink', st.socialLink)}>
                  <a
                    href={`${process.env.REACT_APP_DOMAIN_URL}/login/google?lang=${language}&inv=server`}
                    className={cn('btn wid-100 soc_but_google', st.isMobileApple)}
                  >
                    <img alt="brands_google" src={brandGoogleSvg} />
                    Google
                  </a>
                  {window.ReactNativeWebView ? (
                    <button className={cn('btn wid-100 soc_but_face', st.isMobileApple)} onClick={this.webviewLogin}>
                      <img alt="fa-brands_facebook" src={facebookSvg} />
                      Facebook
                    </button>
                  ) : (
                    <a
                      href={`${process.env.REACT_APP_DOMAIN_URL}/login/facebook?lang=${language}&inv=server`}
                      className={cn('btn wid-100 soc_but_face', st.isMobileApple)}
                    >
                      <img alt="fa-brands_facebook" src={facebookSvg} />
                      Facebook
                    </a>
                  )}
                  {isNavigationMobileIos && (
                    <a
                      href={`${process.env.REACT_APP_DOMAIN_URL}/login/apple?lang=${language}&inv=server`}
                      className="btn wid-100 soc_but_apple text-uppercase"
                    >
                      <div>
                        <img alt="cib_apple" src={appleSvg} />
                      </div>
                      <div style={{ marginTop: '7px' }}>Sign in with Apple</div>
                    </a>
                  )}
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.mainReducer.locale,
  isLoggedIn: state.auth.isLoggedIn,
  isLogging: state.auth.isLogging,
  user: state.auth.user,
  mobileStep: state.userReducer.mobileStep,
  status: state.applicationsReducer.status,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loginUser, setupPreviousStep, loginUserWithToken }, dispatch);
}
// LoginModal.confirm = (props) => {
//   ReactDOM.render(
//     <Provider store={store}>
//       {/* <LoginModal {...props} /> */}
//     </Provider>,
//     document.createElement('div'),
//   );
// };

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
