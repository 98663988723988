import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import xSvg from '../../assets/images/x.svg';
import { addToFavourite, removeFromFavourite, toggleModalShow } from '../../_actions';
import { localeSelector } from '../../_selectors/main.selector';
import { ModalContainer } from '../ModalContainer/ModalContainer';
import { useTranslation } from '../../_hook/useTranslation';
import PriceInfo from './PriceInfo/PriceInfo';
import LimitedInfo from './LimitedInfo/LimitedInfo';
import FavoriteButton from '../../_components/FavoriteButton/FavoriteButton';
import { favoriteCouponsSelector } from '../../_selectors/coupon.selector';
import defaultCouponImage from '../../assets/images/no-coupon.svg';
import st from './QuickReviewModal.module.scss';
import { userSelector } from '../../_selectors/user.selector';
import { authSelector } from '../../_selectors/auth.selector';
import { QUICK_REVIEW_MODAL } from '../../_constants';
import { modalsDataSelector, modalsSelector } from '../../_selectors/modal.selector';
import { isRTL } from '../../_helpers/functions';

const removeCountryCode = (phone) => {
  if (phone.startsWith('972')) {
    return phone.replace('972', '0');
  }
  if (phone.startsWith('+972')) {
    return phone.replace('+972', '0');
  }
  return phone;
};

function QuickReviewModal() {
  const [activeImg, setActiveImg] = useState(null);

  // const user = useSelector(userSelector);
  const { user, isLoggedIn } = useSelector(authSelector);
  const locale = useSelector(localeSelector);
  const favoriteCoupons = useSelector(favoriteCouponsSelector);
  const modals = useSelector(modalsSelector);
  const modalData = useSelector(modalsDataSelector);

  const dispatch = useDispatch();
  const t = useTranslation();

  const { coupon } = modalData[QUICK_REVIEW_MODAL] || {};
  const open = modals.includes(QUICK_REVIEW_MODAL);
  const rtl = locale === 'he';

  let couponDescription = coupon?.description;
  if (couponDescription) {
    couponDescription = replaceNumberWithTag(couponDescription);
    couponDescription = couponDescription.replaceAll('#', '<br/>');
  }

  function replaceNumberWithTag(inputString) {
    const numberPattern = /[0-9]{7,20}/g;
    const whatsappPattern = /w:[0-9]{7,20}/g;

    let replacedString = '';

    if (inputString.match(whatsappPattern)) {
      replacedString = inputString.replace(whatsappPattern, (match) => {
        return `<a href="https://wa.me/${match.split(':')[1]}" target="_blank">${removeCountryCode(
          match.split(':')[1]
        )}</a>`;
      });
    } else {
      replacedString = inputString.replace(numberPattern, (match) => {
        return `<a href="tel:${match}">${match}</a>`;
      });
    }

    return replacedString;
  }

  const providerName = encodeURIComponent(coupon?.company?.name?.replace(/\s/g, '-').split('/').join('-'));

  useEffect(() => {
    setActiveImg(coupon?.imgPath?.[0]);
  }, [coupon]);

  const onClose = () => {
    dispatch(toggleModalShow(QUICK_REVIEW_MODAL));
  };

  const handleAddToFavorite = (e, coupon) => {
    e.preventDefault();
    dispatch(addToFavourite(coupon, locale));
  };

  const handleRemoveFromFavourite = (e, id) => {
    e.preventDefault();
    dispatch(removeFromFavourite(id, locale));
  };

  if (!open) return null;

  return (
    <ModalContainer open={open} modalContentStyles={st.modalWrapperContainer}>
      <div className={cn(st.modalContainer, { [st.rtl]: rtl })}>
        <div role="button" className={st.closeSvg} onClick={onClose}>
          <img src={xSvg} alt="close" />
        </div>

        <div className={st.wrapper}>
          <div className={cn(st.couponInfo)}>
            {coupon && (
              <>
                <h1 className={cn({ 'text-right-force': isRTL(coupon.name) })}>{coupon.name?.replaceAll('/', '/ ')}</h1>
                <div className={cn(st.companyContainer)}>
                  <span className={cn(st.companyName)}>
                    <p className={cn(st.heading)}>{t('company_name')}</p> &nbsp;
                    {coupon.company && (
                      <Link to={`/${locale}/company/${providerName}-p/${coupon.company._id}`} onClick={onClose}>
                        <p className={cn(st.price)}>{coupon.company.name}</p>
                      </Link>
                    )}
                  </span>
                  <span className={cn(st.companyContainer)}>
                    <p className={cn(st.heading)}>{t('pricePerUnit')}</p> &nbsp;
                    <span className={st.priceWrapper}>
                      <p className={st.priceBefore}>₪{coupon.priceBeforeDiscount}</p>
                      <p className={st.price}>₪{coupon.priceAfterDiscount}</p>
                    </span>
                  </span>
                </div>
                <div className={cn(st.descriptionContainer, { 'text-right-force': isRTL(coupon.name) })}>
                  <p dangerouslySetInnerHTML={{ __html: couponDescription }}></p>
                </div>
                <PriceInfo coupon={coupon} rtl={rtl} locale={locale} />
              </>
            )}
          </div>

          <div className={st.swiperContainer}>
            <div className={st.imgContainer}>
              <img
                src={activeImg ? `${process.env.REACT_APP_URL_IMG}${activeImg}` : defaultCouponImage}
                className={st.mainImg}
                alt="slide"
              />
              <FavoriteButton
                variant="couponPageWishlist"
                user={user}
                favouriteCoupons={favoriteCoupons}
                coupon={coupon}
                rtl={rtl}
                inSlider
                isLogged={isLoggedIn}
                handleAddToFavorite={handleAddToFavorite}
                handleRemoveFromFavourite={handleRemoveFromFavourite}
              />
            </div>

            <div className={st.thumbsContainer}>
              <Swiper
                dir={rtl ? 'rtl' : 'ltr'}
                spaceBetween={16}
                slidesPerView={4}
                freeMode
                navigation
                modules={[FreeMode, Navigation, Thumbs]}
                className={st.thumbsSlider}
              >
                {coupon.imgPath?.map((image, i) => (
                  <SwiperSlide
                    key={i}
                    onClick={() => setActiveImg(image)}
                    className={cn({ [st.activeSlide]: image === activeImg })}
                  >
                    <img src={`${process.env.REACT_APP_URL_IMG}${image}`} alt="slide" className={st.thumbsImg} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        <LimitedInfo coupon={coupon} rtl={rtl} />
      </div>
    </ModalContainer>
  );
}

export default QuickReviewModal;
