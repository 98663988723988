import { authHeader } from '../_helpers/auth-header';
import { handleResponse } from '../_helpers';
import cookies from '../_helpers/cookies';

function settingsApplications(app) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    crossDomain: true,
  };

  return fetch(`${process.env.REACT_APP_API_URL}settingsApplication`, requestOptions).then(handleResponse);
}

function getSEO(app) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    crossDomain: true,
  };

  return fetch(`${process.env.REACT_APP_API_URL}seo/all`, requestOptions).then(handleResponse);
}

function getMobileVersion() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    crossDomain: true,
  };

  return fetch(`${process.env.REACT_APP_API_URL}mobileVersion`, requestOptions).then(handleResponse);
}

export function notifyAPKAboutToken(newToken) {
  if (!window.ReactNativeWebView) return;
  const token = newToken ?? cookies.utils.getAuth();
  if (token) {
    window.ReactNativeWebView?.postMessage('loginToken:' + token);
  }
}

export const applicationService = {
  settingsApplications,
  getSEO,
  getMobileVersion,
};
