export const userSelector = (state) => state.auth.user;

export const notificationsSelectors = (state) => state.userReducer.notifications;

export const userUpdateSuccessSelector = (state) => state.userReducer?.updateSuccess;

export const userUpdateErrorSelector = (state) => state.userReducer?.updateError;

export const userShowModalSuccessSelector = (state) => state.userReducer?.showModalSucces;

export const userIsLoadingSelector = (state) => state.userReducer?.isLoading;
