import { modalsConstants } from '../_constants';

export const toggleAgreeModalLoginIn = (agree, agreeModal) => (dispatch) =>
  dispatch({ type: modalsConstants.AGREE_MODAL_LOGIN_IN, agree, agreeModal });

export const toggleModalShow = (name, data) => (dispatch) =>
  dispatch({ type: modalsConstants.TOGGLE_MODAL_SHOW, name, data });

export const openQuickViewModal = (show) => (dispatch) =>
  dispatch({ type: modalsConstants.SHOW_QUICKVIEW_MODAL, show });

export const closeQuickViewModal = (hide) => (dispatch) =>
  dispatch({ type: modalsConstants.HIDE_QUICKVIEW_MODAL, hide });

export const quickViewModalOpen = (previewModal) => (dispatch) =>
  dispatch({ type: modalsConstants.SHOW_QUICKVIEW_MODAL, previewModal });

export const setUpdateMobileAppModal = (show) => (dispatch) =>
  dispatch({ type: modalsConstants.SHOW_MOBILE_UPDATE_MODAL, data: show });
