export const branchConstants = {
  GET_SUCCESS_BRANCH: 'GET_SUCCESS_BRANCH',
  GET_SUCCESS_BRANCHES_FOR_MAP: 'GET_SUCCESS_BRANCHES_FOR_MAP',

  GET_SUCCESS_CATEGORIES_FAVOURITE: 'GET_SUCCESS_CATEGORIES_FAVOURITE',
  GET_FAILURE_CATEGORIES_FAVOURITE: 'GET_FAILURE_CATEGORIES_FAVOURITE',
  GET_SUCCESS_CATEGORIES: 'GET_SUCCESS_CATEGORIES',
  GET_REQUEST_CATEGORIES: 'GET_REQUEST_CATEGORIES',

  GET_SUCCESS_PARENT_CATEGORIES: 'GET_SUCCESS_PARENT_CATEGORIES',
  GET_REQUEST_PARENT_CATEGORIES: 'GET_REQUEST_PARENT_CATEGORIES',

  GET_SUCCESS_COMPANY_BY_ID: 'GET_SUCCESS_COMPANY_BY_ID',
  GET_SUCCESS_HELP_DATA: 'GET_SUCCESS_HELP_DATA',

  REMOVE_REQUEST_CATEGORIES_FAVOURITE: 'REMOVE_REQUEST_CATEGORIES_`FAVOURITE',
  REMOVE_SUCCESS_CATEGORIES_FAVOURITE: 'REMOVE_SUCCESS_CATEGORIES_FAVOURITE',
  REMOVE_FAILURE_CATEGORIES_FAVOURITE: 'REMOVE_FAILURE_CATEGORIES_FAVOURITE',
  ADD_REQUEST_CATEGORIES_FAVOURITE: 'ADD_REQUEST_CATEGORIES_FAVOURITE',
  ADD_SUCCESS_CATEGORIES_FAVOURITE: 'ADD_SUCCESS_CATEGORIES_FAVOURITE',
  ADD_FAILURE_CATEGORIES_FAVOURITE: 'ADD_FAILURE_CATEGORIES_FAVOURITE',
};
