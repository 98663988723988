/* eslint-disable import/no-extraneous-dependencies */
import { toast as alert } from 'react-toastify';
import SvgSuccess from '../assets/images/alerts/success.svg';
import SvgError from '../assets/images/alerts/error.svg';
import SvgInfo from '../assets/images/alerts/info.svg';
import SvgWarning from '../assets/images/alerts/warning.svg';
import 'react-toastify/dist/ReactToastify.css';

export const toast = ({ type, text }) => {
  const icon = type === 'success'
    ? SvgSuccess
    : type === 'error'
      ? SvgError
      : type === 'info'
        ? SvgInfo : SvgWarning;

  alert[type](text, {
    icon: <img src={icon} alt="" />,
  });
};
