import React, { useState } from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { useSelector } from 'react-redux';
import { localeSelector } from '../../_selectors/main.selector';
import { TRANSLATIONS } from '../../_constants';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { renderMonthElements } from './renderMonthElements';
import { useTranslation } from '../../_hook/useTranslation';

import st from './DateRange.module.scss';

function DateRange({ onChange, initialDate, initialDateId }) {
  const [isFocused, setIsFocused] = useState(false);
  const locale = useSelector(localeSelector);
  const t = useTranslation();

  const handleChange = (data) => {
    onChange(initialDateId, data);
  };

  const handleFocus = ({ focused }) => {
    setIsFocused(focused);
  };

  return (
    <SingleDatePicker
      showDefaultInputIcon
      renderMonthElement={(props) => renderMonthElements({ ...props, locale })}
      renderWeekHeaderElement={(props) => (
        <div className={st.weekDay}>
          {t(props)}
        </div>
      )}
      displayFormat={() => 'DD.MM.YYYY'}
      small
      openDirection="up"
      placeholder={TRANSLATIONS[locale].date_of_birth}
      date={initialDate ? moment(initialDate, 'YYYY-MM-DD') : null}
      onDateChange={(date) => handleChange(date)}
      numberOfMonths={1}
      focused={isFocused}
      onFocusChange={handleFocus}
      id={initialDateId}
      maxDate={moment()}
      isOutsideRange={(day) => moment(day) >= moment()}
    />
  );
}

export default DateRange;
