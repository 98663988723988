import React from 'react';
import cn from 'classnames/bind';
import { TRANSLATIONS } from '../../_constants';

// styles
import st from './Realized.module.scss';

const cx = cn.bind(st);

export default function Realized({ coupon, classname, language }) {
  const { redemptions } = coupon;
  return (
    <>
      {redemptions !== 0 && (
        <p className={cx('text', { [classname]: classname })}>
          {`${redemptions} ${(redemptions) === 1
            ? TRANSLATIONS[language].realized_card_one
            : TRANSLATIONS[language].realized_card}`}
        </p>
      )}
    </>
  );
}
