import { useState, useEffect } from 'react';
import { applicationService } from '../_services';
import { useDispatch } from 'react-redux';
import { applicationsConstants } from '../_constants';
import { store } from '../_helpers';

export function useCheckSupportedVersion() {
  useEffect(() => {
    handleCheckMobileVersion();
    window.addEventListener('message', handleFoucesMessage, true);

    return () => {
      window.removeEventListener('message', handleFoucesMessage, true);
    };
  }, []);
}

function handleFoucesMessage(message) {
  if (message?.data === 'mobileFocused') {
    handleCheckMobileVersion();
  }
}

function handleCheckMobileVersion() {
  let mobileVersion = localStorage.getItem('mobileVersion');
  if (!mobileVersion) {
    mobileVersion = '1';
    localStorage.setItem('mobileVersion', mobileVersion);
  }
  applicationService.getMobileVersion().then((response) => {
    store.dispatch({ type: applicationsConstants.SET_MOBILE_VERSIONS, data: response });
    const version = response.CURRENT_MOBILE_APP_VERSION;

    if (version && mobileVersion !== version) {
      localStorage.setItem('mobileVersion', version);
      window.location.reload(true);
    }
  });
}
