import { applicationsConstants } from '../_constants';

const INITIAL_STATE = {
  status: '',
  seo: [],
  mobileVersions: {},
};

export function applicationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case applicationsConstants.SET_APPLICATIONS_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case applicationsConstants.GET_SEO:
      return {
        ...state,
        seo: action.data,
      };

    case applicationsConstants.SET_MOBILE_VERSIONS:
      return {
        ...state,
        mobileVersions: action.data,
      };
    default:
      return state;
  }
}
