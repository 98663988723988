export const mounts = {
  ינואר: 'january',
  פברואר: 'february',
  מרץ: 'march',
  אפריל: 'april',
  מאי: 'may',
  יוני: 'june',
  יולי: 'july',
  אוגוסט: 'august',
  ספטמבר: 'september',
  אוקטובר: 'october',
  נובמבר: 'november',
  דצמבר: 'december',
  Январь: 'january',
  Февраль: 'february',
  Март: 'march',
  Апрель: 'april',
  Май: 'may',
  Июнь: 'june',
  Июль: 'july',
  Август: 'august',
  Сентябрь: 'september',
  Октябрь: 'october',
  Ноябрь: 'november',
  Декабрь: 'december',
};
