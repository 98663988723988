import { branchConstants, TRANSLATIONS } from '../_constants';
import { branchService } from '../_services';
import { toast } from '../_helpers/toast';

export function getBranches(id) {
  return (dispatch) => {
    branchService.getBranches(id).then((data) => {
      dispatch({ type: branchConstants.GET_SUCCESS_BRANCH, data });
    });
  };
}

export function getBranchesForMap(data) {
  return (dispatch) => {
    branchService.getBranchesForMap(data).then((data) => {
      dispatch({ type: branchConstants.GET_SUCCESS_BRANCHES_FOR_MAP, data: data.filter((item) => item.coordinates) });
    });
  };
}

export function getCategories() {
  return (dispatch) => {
    dispatch({ type: branchConstants.GET_REQUEST_CATEGORIES });
    branchService.getCategories().then((data) => {
      dispatch({ type: branchConstants.GET_SUCCESS_CATEGORIES, data });
    });
  };
}

export function getParentCategories() {
  return (dispatch) => {
    dispatch({ type: branchConstants.GET_REQUEST_PARENT_CATEGORIES });
    branchService.getParentCategories().then((data) => {
      dispatch({ type: branchConstants.GET_SUCCESS_PARENT_CATEGORIES, data });
    });
  };
}

export function getFavouriteCategories() {
  return (dispatch) => {
    branchService.getFavouriteCategories().then(
      (data) => {
        dispatch({ type: branchConstants.GET_SUCCESS_CATEGORIES_FAVOURITE, data });
      },
      (error) => {
        dispatch({ type: branchConstants.GET_FAILURE_CATEGORIES_FAVOURITE, error: error.toString() });
      }
    );
  };
}

export function handleCategoryRemove(category, language) {
  return (dispatch) => {
    dispatch({ type: branchConstants.REMOVE_REQUEST_CATEGORIES_FAVOURITE, data: category._id });

    branchService.handleCategorySelect(category._id).then(
      () => {
        toast({ type: 'info', text: TRANSLATIONS[language].category_removed });
      },
      (error) => {
        dispatch({ type: branchConstants.REMOVE_FAILURE_CATEGORIES_FAVOURITE, data: category });
      }
    );
  };
}

export function handleCategorySelect(category, language) {
  return (dispatch) => {
    dispatch({ type: branchConstants.ADD_REQUEST_CATEGORIES_FAVOURITE, data: category });

    branchService.handleCategorySelect(category._id).then(
      () => {
        toast({ type: 'success', text: TRANSLATIONS[language].category_added });
      },
      (error) => {
        dispatch({ type: branchConstants.ADD_FAILURE_CATEGORIES_FAVOURITE, data: category._id });
      }
    );
  };
}

export function getCompanyById(id) {
  return (dispatch) => {
    branchService.getCompanyById(id).then((data) => {
      dispatch({ type: branchConstants.GET_SUCCESS_COMPANY_BY_ID, data });
    });
  };
}

export function getHelpData() {
  return (dispatch) => {
    branchService.getHelpData().then((data) => {
      dispatch({ type: branchConstants.GET_SUCCESS_HELP_DATA, data });
    });
  };
}
