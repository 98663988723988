/* eslint-disable react/jsx-no-useless-fragment */
import React, { Component, useEffect } from 'react';
import ScrollBar from 'react-scrollbars-custom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import { makeRead, clearNotification } from '../../_actions';
import TranslationContainer from '../TranslationContainer';
import withLanguage from '../withLanguage';
import orangeSvg from '../../assets/images/close-orange.svg';
import bellSvg from '../../assets/images/illustration-bell.svg';
import danger from '../../assets/images/notifications/danger.svg';
import info from '../../assets/images/notifications/info.svg';
import success from '../../assets/images/notifications/success.svg';
import st from './Notifications.module.scss';
import Button from '../Button/Button';
import './NotificationsList.scss';
import { useTranslation } from '../../_hook/useTranslation';

const notificationIcons = {
  danger,
  info,
  success,
};

function NotificationsList(props) {
  const t = useTranslation();
  const [notifications, setNotifications] = React.useState([]);
  const { notifications: notificationData, height, language, clearNotification } = props;

  const makeRead = (id) => {
    const { makeRead } = props;
    makeRead(id);
  };

  useEffect(() => {
    setNotifications(notificationData.map((notification) => ({ ...notification, expanded: false })));
  }, [notificationData]);

  const changeExpanded = (id) => {
    setNotifications(
      [...notifications].map((notification) => {
        if (notification.notificationID === id) {
          return { ...notification, expanded: !notification.expanded };
        }
        return notification;
      })
    );
  };

  function formatDate(date) {
    return date ? moment(date).format('DD/MM/YYYY') : '';
  }

  const clear = (e, id) => {
    e.stopPropagation();
    clearNotification(id);
  };

  return (
    <>
      {notifications.length > 0 ? (
        <div className={cn('notificationsList', st.notificationContainer)}>
          <div className="position-relative">
            <ScrollBar
              style={{
                position: '',
                minHeight: '200px',
                height: height ? `${height}px` : '100%',
              }}
              autohide="true"
              noScrollX
              // isRtl
            >
              {notifications.map((notification, index) => {
                const created_at = moment()
                  .utc()
                  .startOf('day')
                  .diff(moment.utc(notification.createdAt).startOf('day'), 'days', false);

                return (
                  <div
                    key={index}
                    className="notification d-flex align-items-center"
                    onClick={() => (notification.readAt === null ? makeRead(notification.notificationID) : null)}
                  >
                    <div>
                      <img
                        className="notification-icon"
                        src={notificationIcons[notification.data.notificationStatus]}
                      />
                    </div>
                    <div>
                      <div
                        onClick={() => changeExpanded(notification.notificationID)}
                        className={cn('titleNotif', { [st.titleNotification]: language === 'he' })}
                      >
                        <p>
                          {notification.data.notificationTitle[language]}
                          {/* .replace('[EN]', '')
                              .replace('[HE]', '')
                              .replace('[RU]', '')} */}
                        </p>
                      </div>

                      <div className={cn('notifWrap', 'notification-info', { 'expanded-info': notification.expanded })}>
                        {notification.data.notificationText && (
                          <div className={cn('description', { [st.descriptionNotification]: language === 'he' })}>
                            <p
                              className={cn('mb-2 notification-info-text', {
                                'pr-2': language !== 'he',
                                'pl-2': language === 'he',
                              })}
                              dangerouslySetInnerHTML={{
                                __html:
                                  notification.data.notificationText[language] +
                                  `${t('on_date')} ${formatDate(notification.createdAt)}</span>`,
                              }}
                            />

                            <p>
                              {created_at === 0 ? (
                                <TranslationContainer translationKey="today" />
                              ) : (
                                <TranslationContainer
                                  translationKey="created_at"
                                  variableBlock={['day']}
                                  valueBlock={[created_at]}
                                />
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={cn('status-container', st.statusContainer)}>
                      <Button
                        type="button"
                        styles={st.deleteBtn}
                        onClick={(e) => clear(e, notification.notificationID)}
                        variant="secondary"
                      >
                        <img src={orangeSvg} alt="notification-svg" />
                      </Button>
                      <div className={`status ${notification.readAt !== null ? 'read' : 'unread'}`} />
                    </div>
                  </div>
                );
              })}
            </ScrollBar>
          </div>
        </div>
      ) : (
        <div className="no_notification">
          <div className="m-3">
            <div className="d-flex justify-content-center">
              <img src={bellSvg} />
            </div>
            <div className="mt-2 d-flex justify-content-center">
              <p>
                <TranslationContainer translationKey="no_notifications" />
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  notifications: state.userReducer.notifications,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      makeRead,
      clearNotification,
    },
    dispatch
  );

export default withLanguage(connect(mapStateToProps, mapDispatchToProps)(NotificationsList));
