import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from '../../_hook/useTranslation';
import AgeModal from '../../_modals/AgeModal';
import quickReviewSvg from '../../assets/images/quickReview.svg';
import st from '../CouponCard/CouponCard.module.scss';
import cn from 'classnames/bind';

export default function QuickReview({ language, coupon, toggleQuickView, rtl, className }) {
  const t = useTranslation();
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (coupon.alcoholAndTobaco?.[0]?.value !== 0) {
          AgeModal.confirm({
            coupon,
            language,
            onOk: toggleQuickView,
          });
        } else {
          toggleQuickView();
        }
      }}
      className={cn(st.quickReview, 'quick-review')}
      data-tip
      data-for={`tooltip-id-review${coupon._id}`}
    >
      <img src={quickReviewSvg} />
      <ReactTooltip
        id={`tooltip-id-review${coupon._id}`}
        place={language === 'he' ? 'left' : 'right'}
        // place="right"
        type="dark"
        effect="solid"
        className={st.tooltip}
      >
        <p className={st.quickReviewTooltip}>{t('quick_view')}</p>
      </ReactTooltip>
    </div>
  );
}
