export const couponSelector = (state) => state.couponReducer;

export const couponStatus = (state) => state.couponReducer.isCouponLoading;

export const allCouponsSelector = (state) => state.couponReducer.coupons;

export const loadingHotCouponsSelector = (state) => state.couponReducer.loadingHotCoupons;

export const favoriteCouponsSelector = (state) => state.couponReducer.favouriteCoupons;

export const isDeletingWishlistSelector = (state) => state.couponReducer.isDeletingWishlist;

export const isCouponsLoadingSelector = (state) => state.couponReducer.isCouponsLoading;
