import { logout } from '../_actions';
import { statusConstants } from '../_constants/status.constants';
import { store } from '.';
import { toast } from './toast';
import { TRANSLATIONS } from '../_constants';

export function handleResponse(response) {
  const locale = window.location.pathname.split('/')[1];
  return response.text().then((text) => {
    let data;
    try {
      data = text && JSON.parse(text);
    } catch (e) {
      data = text;
    }
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;

      const internalError = data && data.errors && data.errors[0] && data.errors[0].message;

      if (internalError) {
        toast({ type: 'error', text: internalError });
        return Promise.reject(error);
      }

      switch (response.status) {
        case statusConstants.UNAUTHORIZED:
          store.dispatch(logout());
          // toast({ type: 'error', text: TRANSLATIONS[locale].you_are_not_logged_in });
          break;
        case statusConstants.FORBIDDEN:
          toast({ type: 'error', text: TRANSLATIONS[locale].access_denied });
          break;
        case statusConstants.NOT_FOUND:
          toast({ type: 'error', text: TRANSLATIONS[locale].not_found });
          break;
        case statusConstants.UNPROCESSABLE_ENTITY:
          toast({ type: 'error', text: TRANSLATIONS[locale].something_went_wrong });
          break;
        default:
          toast({ type: 'error', text: TRANSLATIONS[locale].something_went_wrong });
      }

      return Promise.reject(error);
    }

    return data;
  });
}
