import { Link } from 'react-router-dom';
import suitableIcon from '../../assets/images/menu/suitable.svg';
import feedBackIcon from '../../assets/images/menu/feedback.svg';
import nearbyIcon from '../../assets/images/menu/nearby.svg';
import './SubMenu.scss';
import { useTranslation } from '../../_hook/useTranslation';
import { useLanguage } from '../../_hook/useLanguage';

export default function SubMenu() {
  const t = useTranslation();
  const { locale } = useLanguage();

  return (
    <div className="sub-menu">
      <div className="chipper-container">
        <ul className="sub-menu__list">
          <li className="sub-menu__item">
            <Link to={`/${locale}/nearby`}>
              <img src={nearbyIcon} alt="" /> {t('menu_3')}
            </Link>
          </li>
          <li className="sub-menu__item">
            <Link to={`/${locale}/preffered_categories`}>
              <img src={feedBackIcon} alt="" /> {t('menu_12')}
            </Link>
          </li>
          <li className="sub-menu__item">
            <Link to={`/${locale}/suitable_for_me`}>
              <img src={suitableIcon} alt="" />
              {t('menu_13')}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
