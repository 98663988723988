import { useSelector } from 'react-redux';
import { mainSelector } from '../_selectors/main.selector';

export function useLanguage() {
  const { locale } = useSelector(mainSelector);
  const isRtl = locale === 'he';

  return {
    isRtl,
    locale,
  };
}
