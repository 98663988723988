import React, { useContext, useState } from 'react';
import cn from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../_hook/useTranslation';
import Button from '../../../_components/Button/Button';
import AgeModal from '../../AgeModal';
import LoginModalContext from '../../../_contexts/loginModalContext';
import { authSelector } from '../../../_selectors/auth.selector';
import { toggleAgreeModalLoginIn, toggleModalShow } from '../../../_actions';
import { QR_MODAL } from '../../../_constants';
import { gaRealizationInStore } from '../../../_helpers/createEvent';

import st from './PriceInfo.module.scss';

const cx = cn.bind(st);

export default function PriceInfo({ coupon, rtl, locale }) {
  const { price } = coupon;
  const dispatch = useDispatch();
  const context = useContext(LoginModalContext);
  const { user, isLoggedIn } = useSelector(authSelector);
  const t = useTranslation();
  const isRu = locale === 'ru';

  const isPercentage = coupon?.coupon_type === 'Percentage';

  const handleOpenModal = (e) => {
    gaRealizationInStore();
    e.preventDefault();
    e.stopPropagation();
    const action = () => {
      !isLoggedIn
        ? context.openLogin()
        : user.agree1
        ? dispatch(toggleModalShow(QR_MODAL, { coupon }))
        : dispatch(toggleAgreeModalLoginIn(false, true));
    };
    if (coupon.alcoholAndTobaco[0].value !== 0) {
      AgeModal.confirm({
        coupon,
        language: locale,
        onOk: action,
      });
    } else {
      action();
    }
  };

  return (
    <div className={cx('priceInfoContainer', { rtl })}>
      <div className={cx('priceContainer')}>
        {!isPercentage && <span className={cx('currency', 'pr-2')}>₪</span>}
        <span className={cx('price')}>{!isPercentage ? coupon.price : coupon.percentage + '%'}</span>
        <span className={cx('discount')}>{t('discount')}</span>
      </div>
      <div className={cx('actions', { 'is-ru': isRu })}>
        <Button onClick={handleOpenModal} styles={cx('action-button')}>
          {t('realization_in_store')}
        </Button>
      </div>
    </div>
  );
}
