import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import xSvg from '../../assets/images/x.svg';
import logoFooterSvg from '../../assets/images/logo-footer.svg';
import { ModalContainer } from '../ModalContainer/ModalContainer';
import { userSelector } from '../../_selectors/user.selector';
import { modalsDataSelector, modalsSelector } from '../../_selectors/modal.selector';
import { QR_MODAL } from '../../_constants';
import { useTranslation } from '../../_hook/useTranslation';
import st from './QRModal.module.scss';
import { toggleModalShow } from '../../_actions';

const QRCode = require('qrcode.react');

function QRModal() {
  const user = useSelector(userSelector);
  const modals = useSelector(modalsSelector);
  const modalsData = useSelector(modalsDataSelector);

  const t = useTranslation();
  const dispatch = useDispatch();

  const { coupon } = modalsData[QR_MODAL] || {};
  const open = modals.includes(QR_MODAL);

  const handleClose = () => {
    dispatch(toggleModalShow(QR_MODAL));
  };

  console.log('coupon', coupon);

  if (!open) return null;

  // const redemption = coupon.redemptForUserQ_ty - coupon.redemptAllQ_tyLeft;
  return (
    <ModalContainer open={open} modalContentStyles={st.modalContent}>
      <img role="button" src={xSvg} alt="close" className={st.closeSvg} onClick={handleClose} />

      <div className={st.modalWrapper}>
        <img src={logoFooterSvg} alt="logo" />
        <div className={st.text}>{t('please_display_QR')}</div>
        <div className={st.qr}>
          <QRCode value={`orderToken=${user.orderToken}&couponNumber=${coupon.couponNumber}`} size={240} level="H" />
        </div>
        <div className={st.useCouponNumber}>{t('use_a_coupon_number')}</div>
        <div className={st.couponNumberContainer}>
          <div className={st.couponNumberLabel}>{t('coupon_number')}:</div>
          <div className={st.couponNumber}>{coupon.couponNumber}</div>
        </div>
      </div>
    </ModalContainer>
  );
}

export default QRModal;
