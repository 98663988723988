/* eslint-disable react/button-has-type */
import React from 'react';
import cn from 'classnames';
import st from './Button.module.scss';

function Button({
  children,
  onClick,
  styles,
  disabled,
  variant,
  locale,
  type = 'button',
  ...props
}) {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={cn(st.button, styles, { [st[variant]]: variant, [st.rtl]: locale === 'he' })}
      {...props}
    >
      {children}
    </button>
  );
}

export default Button;
