import { useEffect } from 'react';

export function useGetMobileBuildVersion() {
  const getMobileBuildVersion = (message) => {
    // const data = JSON.parse(message.data);
    if (typeof message.data !== 'string') return;
    if (message?.data?.includes('chipper-app-version:')) {
      handleCheckMobileVersion(message.data);
    }
  };

  const handleCheckMobileVersion = (data) => {
    const mobileVersion = data.substring(data.indexOf(':') + 1, data.indexOf('--'));
    const os = data.substring(data.indexOf('--') + 2);
    localStorage.setItem('mobileBuildVersion', mobileVersion);
    localStorage.setItem('os', os);
  };

  useEffect(() => {
    window.addEventListener('message', getMobileBuildVersion, true);

    return () => {
      window.removeEventListener('message', getMobileBuildVersion, true);
    };
  }, []);
}
