import React from 'react';
import loadingSvg from '../../assets/images/loading.svg';

// styles
import st from './Loader.module.scss';

function Loader() {
  return (
    <div
      className={st.loader}
    >
      <img
        alt="loading"
        src={loadingSvg}
      />
    </div>
  );
}
export default Loader;
