import moment from 'moment';
import { TRANSLATIONS } from '../../_constants';
import { mounts } from '../../_constants/month.constants';
import InputSelect from '../Select/InputSelect';

import st from './renderMonthElements.module.scss';

const year = moment().year();

const yearsOptions = Array.from({ length: 100 }, (_, i) => ({
  label: year - i, value: `id_${year - i}`,
}));

export const renderMonthElements = ({
  month, onMonthSelect, onYearSelect, locale,
}) => {
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      color: state.selectProps.menuColor,
      textAlign: 'left',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;

      return { ...provided, opacity };
    },
  };

  const monthOptions = [
    TRANSLATIONS[locale].january,
    TRANSLATIONS[locale].february,
    TRANSLATIONS[locale].march,
    TRANSLATIONS[locale].april,
    TRANSLATIONS[locale].may,
    TRANSLATIONS[locale].june,
    TRANSLATIONS[locale].july,
    TRANSLATIONS[locale].august,
    TRANSLATIONS[locale].september,
    TRANSLATIONS[locale].october,
    TRANSLATIONS[locale].november,
    TRANSLATIONS[locale].december,
  ].map((label, value) => ({ label, value }));

  return (
    <div className={st.containerDate}>
      <div className="year">
        <InputSelect
          classNamePrefix="select"
          defaultValue={{ label: month.year(), value: `id_${month.year()}` }}
          onChange={(e) => {
            onYearSelect(month, e.label);
          }}
          options={yearsOptions}
          isRtl={locale === 'he'}
        />
      </div>
      <div className="month">
        <InputSelect
          styles={customStyles}
          classNamePrefix="select"
          variant="primary"
          defaultValue={monthOptions.find(((e) => e.value === month.month()))}
          onChange={(e) => {
            onMonthSelect(month, locale !== 'en' ? mounts[e.label] : e.label);
          }}
          options={monthOptions}
          isRtl={locale === 'he'}
        />
      </div>
    </div>
  );
};
