import { useQuery } from '@tanstack/react-query';
import { branchService } from '../../_services';

export function useParentCategories() {
  return useQuery({
    queryKey: ['parentCategories'],
    queryFn: () => branchService.getParentCategories(),
  });
}

export function useCategory(id) {
  return useQuery({
    queryKey: ['category', id],
    queryFn: () => branchService.getCategory(id),
  });
}

export function useSubCategories() {
  return useQuery({
    queryKey: ['subCategories'],
    queryFn: () => branchService.getCategories(),
  });
}

export function useParentAndChildCategories() {
  return useQuery({
    queryKey: ['parentAndChildCategories'],
    queryFn: () => branchService.getParentAndChildCategories(),
  });
}
