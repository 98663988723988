import React, { useMemo } from 'react';
import cn from 'classnames/bind';
import moment from 'moment';
import { useTranslation } from '../../../_hook/useTranslation';
import checkCircleSvg from '../../../assets/images/Check-circle.svg';
import calendarSvg from '../../../assets/images/Calendar.svg';
import timeSvg from '../../../assets/images/time.svg';

import st from './LimitedInfo.module.scss';

const cx = cn.bind(st);

export default function LimitedInfo({ coupon, rtl }) {
  const t = useTranslation();
  const endDate = coupon && moment(coupon.expired_at);
  const currDate = coupon && moment().startOf('day').toDate();
  const daysToExpiry = coupon
    && Math.round(moment.duration(endDate.diff(currDate)).asDays());

  const daysToExpiryLabel = useMemo(() => {
    if (daysToExpiry > 0) {
      if (rtl) return `בעוד ${daysToExpiry}`;
      return daysToExpiry;
    }
    return '';
  }, [daysToExpiry, rtl]);

  const expiryDays = useMemo(() => {
    if (daysToExpiry > 1) {
      return (
        `${daysToExpiryLabel} ${t('days')}`
      );
    }
    if (daysToExpiry === 0) {
      return (
        `${daysToExpiryLabel} ${t('today')}`
      );
    }
    return (
      `${t('in')} ${daysToExpiryLabel} ${t('day')}`
    );
  }, [daysToExpiry, daysToExpiryLabel]);

  return (
    <div className={cx('limitedInfoContainer', { rtl })}>
      {coupon.redemptions > 0 && (
        <div className={cx('limited')}>
          <div>
            <img src={checkCircleSvg} alt="realized" />
          </div>
          <span className={cx('time')}>
            <p className={cx('realize')}>{coupon.redemptions}</p>
            <p className={cx('realizedAbout')}>{t('realized')}</p>
          </span>
        </div>
      )}
      <div className={cx('limited')}>
        <div>
          <img src={calendarSvg} alt="realized" />
        </div>
        <span className={cx('time')}>
          <p className={cx('realize')}>
            {expiryDays}
          </p>

          <p className={cx('realizedAbout')}>{t('deals_end')}</p>
        </span>
      </div>
      <div className={cx('limited')}>
        <div>
          <img src={timeSvg} alt="realized" />
        </div>
        <span className={cx('time')}>
          <p className={cx('realize')}>{t('limited_time_desc')}</p>
        </span>
      </div>
    </div>
  );
}
