import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { toggleAgreeModalLoginIn } from '../../_actions';
import AgeModal from '../../_modals/AgeModal';
import LoginModalContext from '../../_contexts/loginModalContext';
import TranslationContainer from '../TranslationContainer';
import heartSvgFill32 from '../../assets/images/heart-32-fill.svg';
import favoriteSvg from '../../assets/images/heart-32.svg';

// styles
import st from './FavoriteButton.module.scss';

const cx = cn.bind(st);

export default function FavoriteButton({
  rtl,
  isLogged,
  user,
  language,
  coupon,
  favouriteCoupons,
  inSlider,
  handleAddToFavorite,
  handleRemoveFromFavourite,
  variant,
}) {
  const tooltipContainer = document.getElementById('tooltip-container');
  const dispatch = useDispatch();
  const favourite =
    coupon &&
    favouriteCoupons &&
    favouriteCoupons.some((couponFromFavourite) => couponFromFavourite._id === coupon._id);
  const context = useContext(LoginModalContext);
  return (
    <>
      {isLogged ? (
        !favourite ? (
          <div
            className={`Heart ${cx('heart', { rtl, [variant]: variant })}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const action = () => {
                !isLogged
                  ? context.openLogin()
                  : user.agree1
                  ? handleAddToFavorite(e, coupon)
                  : dispatch(toggleAgreeModalLoginIn(false, true));
              };
              if (coupon.alcoholAndTobaco?.[0]?.value !== 0) {
                AgeModal.confirm({
                  coupon,
                  language,
                  onOk: action,
                });
              } else {
                action();
              }
            }}
            data-tip
            data-for={!inSlider && `tooltip-id${coupon._id}`}
          >
            <img src={favoriteSvg} />
            {inSlider ? (
              <div className={st.sliderTooltip}>
                <p className="error-msg">
                  <TranslationContainer translationKey="add_to_saved_coupons" />
                </p>
              </div>
            ) : (
              ReactDOM.createPortal(
                <ReactTooltip
                  id={`tooltip-id${coupon._id}`}
                  place={rtl ? 'left' : 'right'}
                  type="dark"
                  effect="solid"
                  className="tooltip-element-custom"
                >
                  <p>
                    <TranslationContainer translationKey="add_to_saved_coupons" />
                  </p>
                </ReactTooltip>,
                tooltipContainer
              )
            )}
          </div>
        ) : (
          <div
            className={cx('heart', { rtl, [variant]: variant })}
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveFromFavourite(e, coupon._id);
            }}
            data-tip
            data-for={!inSlider && `tooltip-id_del${coupon._id}`}
          >
            <img src={heartSvgFill32} />
            {inSlider ? (
              <div className={st.sliderTooltip}>
                <p>
                  <TranslationContainer translationKey="remove_from_saved_coupons" />
                </p>
              </div>
            ) : (
              ReactDOM.createPortal(
                <ReactTooltip
                  id={`tooltip-id_del${coupon._id}`}
                  place={rtl ? 'left' : 'right'}
                  type="dark"
                  effect="solid"
                  className="tooltip-element-custom"
                >
                  <p>
                    <TranslationContainer translationKey="remove_from_saved_coupons" />
                  </p>
                </ReactTooltip>,
                tooltipContainer
              )
            )}
          </div>
        )
      ) : (
        <div
          className={cx('heart', { rtl, [variant]: variant })}
          onClick={(e) => {
            e.stopPropagation();
            context.openLogin(e);
          }}
        >
          <img src={favoriteSvg} />
        </div>
      )}
    </>
  );
}
