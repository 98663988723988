import React from 'react';
import cn from 'classnames/bind';
import { Link } from 'react-router-dom';
import locationSvg from '../../assets/images/location.svg';

// styles
import st from './Location.module.scss';

const cx = cn.bind(st);

export default function Location({
  language, coupon, rtl, variant,
}) {
  const { company } = coupon;
  return (
    company ? (
      <div className={cx('locationContainer', { [st[variant]]: variant, [st.rtl]: rtl })}>
        <img src={locationSvg} alt="location" />
        <Link to={`/${language}/company/${company._id}`}>
          <p className={cx('name', { rtl })}>{company.name}</p>
        </Link>
      </div>
    ) : null
  );
}
