import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { mainSelector } from '../../_selectors/main.selector';
import { useTranslation } from '../../_hook/useTranslation';
import { authSelector } from '../../_selectors/auth.selector';
import { favoriteCouponsSelector } from '../../_selectors/coupon.selector';
import {
  addToFavourite, removeFromFavourite, toggleModalShow,
} from '../../_actions';
import Location from '../Location/Location';
import AgeModal from '../../_modals/AgeModal';
import QuickReview from '../QuickReview/QuickReview';
import Realized from '../Realized/Realized';
import TranslationContainer from '../TranslationContainer';
import Stripe from '../Stripe/Stripe';
import FavoriteButton from '../FavoriteButton/FavoriteButton';
import defaultCouponImage from '../../assets/images/no-coupon.svg';
import { hebrewLanguageCheck } from '../../_helpers/hebrewLanguageCheck';
import st from './CouponCard.module.scss';
import { QUICK_REVIEW_MODAL } from '../../_constants';

const cx = cn.bind(st);

export default function CouponCard({
  coupon, inSlider, variant, classname,
}) {
  const history = useHistory();
  const { locale } = useSelector(mainSelector);
  const { user, isLoggedIn } = useSelector(authSelector);
  const favoriteCoupons = useSelector(favoriteCouponsSelector);
  const dispatch = useDispatch();
  const t = useTranslation();

  const rtl = locale === 'he';
  const isImageExist = coupon?.imgPath && coupon?.imgPath[0];
  const imgPath = isImageExist ? process.env.REACT_APP_URL_IMG + coupon.imgPath[0] : defaultCouponImage;

  const handleAddToFavorite = (e, coupon) => {
    e.preventDefault();
    dispatch(addToFavourite(coupon, locale));
  };

  const handleRemoveFromFavourite = (e, id) => {
    e.preventDefault();
    dispatch(removeFromFavourite(id, locale));
  };

  const handleOpenModal = () => {
    dispatch(toggleModalShow(QUICK_REVIEW_MODAL, { coupon }));
  };

  const isSpecialDetails = classname === 'specialDetails';

  const couponUlr = coupon?.url?.split('/')?.join('-');

  return (
    <div className={cx('mainContainer', { rtl }, { [variant]: variant }, { [classname]: classname })}>
      {coupon && (
      <div onClick={() => {
        const action = () => {
          history.push({
            pathname: `/${locale}/coupon/${couponUlr}-coupon/${coupon._id}`,
            state: {
              pathname: history.location.pathname,
              initialScrollY: window.scrollY,
            },
          });
        };

        if (coupon.alcoholAndTobaco[0].value !== 0) {
          AgeModal.confirm({
            coupon,
            locale,
            onOk: action,
          });
        } else {
          action();
        }
      }}
      >
        <Location language={locale} coupon={coupon} rtl={rtl} variant={variant} />
        <Stripe coupon={coupon} rtl={rtl} variant={variant} classname={classname} />
        <div className={st.imageContainer}>
          <img className={st.image} src={imgPath} alt="coupon" />
        </div>
        <div className={st.actionsContainer}>
          <QuickReview
            rtl={rtl}
            coupon={coupon}
            language={locale}
            toggleQuickView={handleOpenModal}
          />
          <FavoriteButton
            rtl={rtl}
            inSlider={inSlider}
            coupon={coupon}
            isLogged={isLoggedIn}
            user={user}
            language={locale}
            favouriteCoupons={favoriteCoupons}
            handleAddToFavorite={handleAddToFavorite}
            handleRemoveFromFavourite={handleRemoveFromFavourite}
          />
        </div>
        <div className={st.infoContainer}>
          <div className={st.descriptionContainer}>
            <p
              dir={hebrewLanguageCheck(coupon.name) ? 'rtl' : 'ltr'}
              className={cx(
                'description',
                {
                  sliced: coupon.redemptions > 0,
                  rtlName: hebrewLanguageCheck(coupon.name),
                },
              )}
            >
              {coupon.name}
            </p>
            <div>
              <div className={cx('realizedContainer')}>
                {isSpecialDetails && (
                <button className="btn btn-primary btn-lg wid-100">
                  <TranslationContainer translationKey="chipper_me" />
                </button>
                )}
                {!isSpecialDetails && <Realized language={locale} coupon={coupon} />}
                {(isSpecialDetails && window.innerWidth > 768) && <Realized language={locale} coupon={coupon} />}
              </div>
            </div>
          </div>
          <div className={st.priceContainer}>
            {isSpecialDetails && window.innerWidth <= 768 && <Realized language={locale} coupon={coupon} classname={classname} />}
            {rtl
              ? (
                <span className={st.price}>
                  <span className={st.currency}>₪</span>
                  {coupon.price}
                </span>
              )
              : (
                <span className={st.price}>
                  {coupon.price}
                  <span className={st.currency}>₪</span>
                </span>
              )}
            <p className={cx('discount')}>{t('discount')}</p>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}
