import { modalsConstants } from '../_constants';

const INITIAL_STATE = {
  modals: [],
  modalsData: {},
  agree: true,
  agreeModal: false,
  quickViewData: {}, // GET DATA FOR EACH DATA COUPON
  previewModal: true,
  age: false,
  updateMobileApp: false,
};

const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case modalsConstants.TOGGLE_MODAL_SHOW:
      return {
        ...state,
        modals: state.modals.includes(action.name)
          ? state.modals.filter((m) => m !== action.name)
          : [...state.modals, action.name],
        modalsData: { ...state.modalsData, [action.name]: action.data },
      };
    case modalsConstants.SHOW_QUICKVIEW_MODAL:
      return {
        ...state,
        previewModal: true,
      };
    case modalsConstants.HIDE_QUICKVIEW_MODAL:
      return {
        ...state,
        previewModal: false,
      };

    case modalsConstants.AGREE_MODAL_LOGIN_IN:
      return {
        ...state,
        agree: action.agree,
        agreeModal: action.agreeModal,
      };

    case modalsConstants.SET_RESPONSE_QUICK_VIEW_DATA:
      return {
        ...state,
        quickViewData: action.data,
      };

    case modalsConstants.SHOW_MOBILE_UPDATE_MODAL:
      return {
        ...state,
        updateMobileApp: action.data,
      };
    default:
      return state;
  }
};

export default modalReducer;
