import './CategoriesDesktopMenu.scss';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import CategoryContent from './CategoryContent';
import { useParentAndChildCategories } from '../../_hook/query/categories';
import { useLanguage } from '../../_hook/useLanguage';
import { useState } from 'react';

export default function CategoriesDesktopMenu() {
  const [value, setValue] = useState('');
  const { data: menu } = useParentAndChildCategories();
  const { locale } = useLanguage();

  const filteredMenu = menu?.filter(parent => parent.childCategories?.length > 0);

  return (
    <div className="categories-wrapper">
      <div className="chipper-container">
        <NavigationMenu.Root dir={locale === 'he' ? 'rtl' : 'ltr'} value={value} onValueChange={setValue}>
          <NavigationMenu.List className="categories-desktop">
          {filteredMenu?.map((parent) => (
              <NavigationMenu.Item key={parent.parentId} className="categories-desktop__item">
                <NavigationMenu.Trigger
                  dir="rtl"
                  onClick={(e) => e.preventDefault()}
                  className="categories-desktop__trigger"
                >
                  {parent.name?.[locale]}
                </NavigationMenu.Trigger>
                <NavigationMenu.Content className="categories-desktop__content">
                  <CategoryContent onLinkClick={() => setValue('')} parentCategory={parent} />
                </NavigationMenu.Content>
              </NavigationMenu.Item>
            ))}
          </NavigationMenu.List>
          <div className="ViewportPosition">
            <NavigationMenu.Viewport className="NavigationMenuViewport" />
          </div>
        </NavigationMenu.Root>
        <div>
          {/* <div className="categories-desktop__item">Fish & Seafood</div>
          <div className="categories-desktop__item">Grocery products</div>
          <div className="categories-desktop__item">Dairy & Egg</div>
          <div className="categories-desktop__item">Frozen products</div>
          <div className="categories-desktop__item">Deli & Salads</div> */}
        </div>
        {/* <CategoryContent /> */}
      </div>
    </div>
  );
}
