import React, { useEffect } from 'react';
import './PublishModal.scss';
import xSvg from '../assets/images/publish-modal/ic_baseline-close.svg';
import frame from '../assets/images/publish-modal/frame.png';
import { useLanguage } from '../_hook/useLanguage';
import { TRANSLATIONS } from '../_constants';
import { Link } from 'react-router-dom';
import { useTimeout } from '../_hook/useTimeout';
import { useSelector } from 'react-redux';

function PublishModal() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [showModal, setShowModal] = React.useState(false);
  const { locale } = useLanguage();

  useEffect(() => {
    let showModalKey;
    let showModalValue;
    if (isLoggedIn !== undefined) {
      showModalKey = isLoggedIn ? 'showPublishModalLoggedIn' : 'showPublishModalNotLoggedIn';
      showModalValue = sessionStorage.getItem(showModalKey);
    }

    if (showModalValue !== 'false') {
      const timeoutId = setTimeout(
        () => {
          setShowModal(true);
        },
        1000 * 60 * 2
      );
      return () => clearTimeout(timeoutId);
    }
  }, [isLoggedIn]);

  const handleModalClose = () => {
    setShowModal(false);
    const showModalKey = isLoggedIn ? 'showPublishModalLoggedIn' : 'showPublishModalNotLoggedIn';
    sessionStorage.setItem(showModalKey, 'false');
  };

  return (
    <div>
      {showModal && (
        <div className="publish-modal-wrapper">
          <div className="publish-modal">
            <button className="close-button" onClick={handleModalClose}>
              <img alt="x" src={xSvg} />
            </button>
            <div className="content">
              <img src={frame} className="frame" alt="frame" />
              <h2>{TRANSLATIONS[locale]['publish_modal_text']}</h2>
              {/* Lorem ipsum dolor sit amet consectetur. Egestas malesuada sed ornare at quam in convallis pharetra vitae. */}
            </div>
            <Link onClick={handleModalClose} className="link" to={`/${locale}/register_as_manufacturer`}>
              {TRANSLATIONS[locale]['publish_modal_button']}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default PublishModal;
