import { Link } from 'react-router-dom';
import { getImageOrPlaceholder } from '../../_helpers/image';
import { useLanguage } from '../../_hook/useLanguage';
import './CategoryContent.scss';
import { replaceBlanks } from '../../_helpers/functions';

export default function CategoryContent({ parentCategory, onLinkClick }) {
  const { locale } = useLanguage();
  const title = parentCategory.name?.[locale];

  return (
    <div className="chipper-container">
      <div className="category-content" style={{ textAlign: locale === 'he' ? 'right' : 'left' }}>
        <div>
          <h2 onClick={onLinkClick} className="category-content__title">
            <Link to={`/${locale}/main-category/${replaceBlanks(parentCategory.name?.en)}/${parentCategory.parentId}`}>
              {title}
            </Link>
          </h2>
          <div className="category-content__menu">
            <ul>
              {parentCategory.childCategories?.map((category) => (
                <li onClick={onLinkClick} key={category.id}>
                  <Link to={`/${locale}/category/${replaceBlanks(category.name?.en)}/${category.id}`}>
                    {category.name?.[locale]}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <img className="category-content__image" src={getImageOrPlaceholder(parentCategory.image)} alt="" />
        </div>
      </div>
    </div>
  );
}
