import React from 'react';
import moment from 'moment';
import cn from 'classnames/bind';
import TranslationContainer from '../TranslationContainer';

// styles
import st from './Stripe.module.scss';

const cx = cn.bind(st);

export default function Stripe({
  coupon, rtl, variant, classname,
}) {
  const currentDate = coupon && moment().startOf('day').toDate();
  const endDate = coupon && moment(coupon.expired_at);
  const expiredAt = coupon && Math.round(moment.duration(endDate.diff(currentDate)).asDays());

  return (
    <div className={cx('stripeContainer', { [st.rtl]: rtl, [st[variant]]: variant && classname !== 'specialDetails', [classname]: classname })}>
      <span className={st.stripe}>
        {expiredAt > 0
          ? (
            <TranslationContainer
              translationKey="day_left"
              variableBlock={['num1']}
              valueBlock={[expiredAt]}
            />
          )
          : <TranslationContainer translationKey="ending_today" />}
      </span>
    </div>
  );
}
