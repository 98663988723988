export const userConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  STEP_TO_UPDATE: 'STEP_TO_UPDATE',
  USER_UPDATE: 'USER_UPDATE',
  USER_FAILURE: 'USER_FAILURE',

  HIDE_CODE: 'HIDE_CODE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  UPDATE_USER_ADDRESS_REQUEST: 'UPDATE_USER_ADDRESS_REQUEST',
  UPDATE_USER_ADDRESS_SUCCESS: 'UPDATE_USER_ADDRESS_SUCCESS',
  UPDATE_USER_ADDRESS_FAILURE: 'UPDATE_USER_ADDRESS_FAILURE',

  SHOW_MODAL_UPDATE_SUCCESS: ' SHOW_MODAL_UPDATE_SUCCESS',
  SHOW_MODAL_UPDATE_FAILURE: 'SHOW_MODAL_UPDATE_FAILURE',
  SHOW_MODAL_UPDATE_REQUEST: 'SHOW_MODAL_UPDATE_REQUEST',

  REQUEST_RECOVER_PASSWORD: 'REQUEST_RECOVER_PASSWORD',
  SUCCESS_RECOVER_PASSWORD: 'SUCCESS_RECOVER_PASSWORD',
  FAILURE_RECOVER_PASSWORD: 'FAILURE_RECOVER_PASSWORD',

  SETUP_FORGOT_STEP: 'SETUP_FORGOT_STEP',

  REQUEST_CHECK_CODE: 'REQUEST_CHECK_CODE',
  SUCCESS_CHECK_CODE: 'SUCCESS_CHECK_CODE',
  FAILURE_CHECK_CODE: 'FAILURE_CHECK_CODE',

  REQUEST_SET_NEW_PASSWORD: 'REQUEST_SET_NEW_PASSWORD',
  SUCCESS_SET_NEW_PASSWORD: 'SUCCESS_SET_NEW_PASSWORD',
  FAILURE_SET_NEW_PASSWORD: 'FAILURE_SET_NEW_PASSWORD',

  REQUEST_NEW_COMPANY: 'REQUEST_NEW_COMPANY',
  SUCCESS_NEW_COMPANY: 'SUCCESS_NEW_COMPANY',
  FAILURE_NEW_COMPANY: 'FAILURE_NEW_COMPANY',

  REQUEST_CONTACT: 'REQUEST_CONTACT',
  SUCCESS_CONTACT: 'SUCCESS_CONTACT',
  FAILURE_CONTACT: 'FAILURE_CONTACT',

  LOGIN_SENT_PHONE_REQUEST: 'LOGIN_SENT_PHONE_REQUEST',
  LOGIN_SENT_PHONE_SUCCESS: 'LOGIN_SENT_PHONE_SUCCESS',
  LOGIN_SENT_PHONE_FAILURE: 'LOGIN_SENT_PHONE_FAILURE',

  LOGIN_SENT_MAIL_REQUEST: 'LOGIN_SENT_MAIL_REQUEST',
  LOGIN_SENT_MAIL_SUCCESS: 'LOGIN_SENT_MAIL_SUCCESS',
  LOGIN_SENT_MAIL_FAILURE: 'LOGIN_SENT_MAIL_FAILURE',

  LOGIN_SENT_PHONE_PREVIOUS_STEP: 'LOGIN_SENT_PHONE_PREVIOUS_STEP',

  REQUEST_CLOSE_SUCCESS_WINDOW: 'REQUEST_CLOSE_SUCCESS_WINDOW',

  CLEARED_NOTIFICATIONS: 'CLEARED_NOTIFICATIONS',
  CLEARED_NOTIFICATION: 'CLEARED_NOTIFICATION',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  MAKE_READ: 'MAKE_READ',

  GET_TRANSACTIONS: 'GET_TRANSACTIONS',
  UPDATE_USER_TRANSACTIONS: 'UPDATE_USER_TRANSACTIONS',

  GET_PAYMENTS_CARD: 'GET_PAYMENTS_CARD',

  UPDATE_USER_ADDRESS: 'UPDATE_USER_ADDRESS',

  UPDATE_AUTH_ERROR: 'UPDATE_AUTH_ERROR',

  LOGOUT: 'LOGOUT',
};
