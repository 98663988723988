import './CouponExpiration.scss';
import calendar from '../../assets/images/calendar-white.svg';
import moment from 'moment';
import TranslationContainer from '../TranslationContainer';

export default function CouponExpiration({ expiresAt, isSpecial }) {
  const currentDate = expiresAt && moment().startOf('day').toDate();
  const endDate = expiresAt && moment(expiresAt);
  const expiredAt = expiresAt && Math.round(moment.duration(endDate.diff(currentDate)).asDays());

  return (
    <div className={`coupon-expiration ${isSpecial ? 'coupon-expiration--special' : ''}`}>
      <img src={calendar} alt="" />
      <span>
        {expiredAt > 0 ? (
          <TranslationContainer translationKey="day_left" variableBlock={['num1']} valueBlock={[expiredAt]} />
        ) : (
          <TranslationContainer translationKey="ending_today" />
        )}
      </span>
    </div>
  );
}
