import { useSelector } from 'react-redux';
import { TRANSLATIONS } from '../_constants';
import { localeSelector } from '../_selectors/main.selector';

export const useTranslation = () => {
  const locale = useSelector(localeSelector);

  return (text) => {
    const translated = TRANSLATIONS[locale][text];
    return translated || text;
  };
};
