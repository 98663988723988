import React from 'react';
import Select from 'react-select';
import cn from 'classnames';
import st from './InputSelect.module.scss';

function InputSelect({
  variant,
  isRtl,
  ...props
}) {
  return (
    <Select
      className={cn(st.select, { [st.rtl]: isRtl })}
      isRtl={isRtl}
      {...props}
    />
  );
}

export default InputSelect;
