export const modalsConstants = {
  AGREE_MODAL_LOGIN_IN: 'AGREE_MODAL_LOGIN_IN',
  TOGGLE_MODAL_SHOW: 'TOGGLE_MODAL_SHOW',
  AGE_MODAL_CONFIRMATION: 'AGE_MODAL_CONFIRMATION',

  SET_RESPONSE_QUICK_VIEW_DATA: 'SET_RESPONSE_QUICK_VIEW_DATA',
  SHOW_QUICKVIEW_MODAL: 'SHOW_QUICKVIEW_MODAL',
  HIDE_QUICKVIEW_MODAL: 'HIDE_QUICKVIEW_MODAL',
  SHOW_MOBILE_UPDATE_MODAL: 'SHOW_MOBILE_UPDATE_MODAL',
};

export const QUICK_REVIEW_MODAL = 'QUICK_REVIEW_MODAL';
export const QR_MODAL = 'QR_MODAL';
