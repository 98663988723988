import './CategoriesMobile.scss';
import arrowLeft from '../../assets/images/arrow-left.svg';
import closeGray from '../../assets/images/close-gray.svg';
import arrowRight from '../../assets/images/chevron-right-black.svg';
import { useState } from 'react';
import { useParentAndChildCategories } from '../../_hook/query/categories';
import { useLanguage } from '../../_hook/useLanguage';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../_hook/useTranslation';
import { replaceBlanks } from '../../_helpers/functions';

export default function CategoriesMobile({ onBack, onClose }) {
  const { data: menu } = useParentAndChildCategories();
  const { locale } = useLanguage();
  const [parent, setParent] = useState(null);
  const t = useTranslation();

  const filteredMenu = menu?.filter((parent) => parent.childCategories?.length > 0);

  const handleBack = () => {
    if (parent) {
      setParent(null);
      return;
    }
    onBack?.();
  };

  return (
    <div className="categories-mobile">
      <div className="categories-mobile__header">
        <button onClick={handleBack}>
          <img className="arrow-left" src={arrowLeft} alt="" />
          {!parent ? t('main_menu') : t('all_categories')}
        </button>
        <img onClick={() => onClose?.()} className="close-arrow" src={closeGray} alt="" />
      </div>
      <div className="categories-mobile__content">
        {parent && (
          <Link
            onClick={() => onClose?.()}
            to={`/${locale}/main-category/${replaceBlanks(parent.name?.en)}/${parent.parentId}`}
            className="categories-mobile__parent"
          >
            {parent.name?.[locale]}
          </Link>
        )}
        <div className="categories-mobile__list">
          {!parent &&
            filteredMenu.map((parent, index) => (
              <button onClick={() => setParent(parent)} key={parent.parentId} className="categories-mobile__item">
                <span>{parent.name?.[locale]}</span>
                <img src={arrowRight} alt="" />
              </button>
            ))}
          {parent &&
            parent.childCategories.map((category, index) => (
              <Link
                onClick={() => onClose()}
                to={`/${locale}/category/${replaceBlanks(category.name?.en)}/${category.id}`}
                key={category.id}
                className="categories-mobile__item"
              >
                <span>{category.name?.[locale]}</span>
                <img src={arrowRight} alt="" />
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
}
