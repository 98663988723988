import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from './_helpers';
import App from './App/App';
import ReactGA from 'react-ga4';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

ReactGA.initialize('G-2LTLQG6QRX');

Sentry.init({
  dsn: 'https://a057a72830e6460daa131ff0640c3f51@o4504717438222336.ingest.sentry.io/4504796474507264',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: process.env.REACT_APP_production,
  environment: process.env.REACT_APP_SENTRY_ENV,
  release: '1.0.0',
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('app')
);
