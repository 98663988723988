/* eslint-disable react/button-has-type */
import React from 'react';
import cn from 'classnames';
import st from './Input.module.scss';

function Input({
  styles,
  containerStyles,
  error,
  icon,
  onChange,
  ...props
}) {
  return (
    <div className={cn(st.inputContainer, containerStyles)}>
      <input
        {...props}
        className={cn(st.input, styles, { [st.inputError]: error })}
        onChange={onChange || props.onChange}
      />
      {icon}
    </div>
  );
}

export default Input;
